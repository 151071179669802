import { Switch, Route } from "react-router-dom";
import './App.css'
import SimpleImageSlider from 'react-simple-image-slider';
import HomePage from "./pages/HomePage";
import AboutPage from "./pages/AboutPage";
import FaqPage from "./pages/FaqPage";
import Header from "./components/Header";

const images = [{ url: '1.jpg' }, { url: '2.jpg' }];

function App() {
  return (
    <div className="App">
      <SimpleImageSlider
        style={{ margin: '0', marginTop: '0', position: 'absolute', zIndex: -100 }}
        width={'100%'}
        height={320}
        images={images}
        showBullets={false}
        showNavs={false}
        loop={true}
        autoPlay={true}
        slideDuration={3}
        startIndex={0} />
      <Header />
      <Switch>
        <Route path="/" exact={true} component={HomePage} />
        <Route path="/over" exact={true} component={AboutPage} />
        <Route path="/faq" component={FaqPage} />
      </Switch>
    </div>
  );
}

export default App;