import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";

import Footer from "../components/Footer";

const client = new ApolloClient({
  uri: 'https://48p1r2roz4.sse.codesandbox.io',
  cache: new InMemoryCache()
});

function HomePage() {
    return (
      <div>
        <div className='home-text-wrapper'>
          <h1>Thuispagina</h1>
          <p>Welkom op de officiële pagina van <b>Tot Nut En Genoegen</b>. <br /><br />
          Deze pagina is nog in de maak. Hier is binnenkort meer informatie over de boerderij te vinden.</p>
        </div>
        <Footer></Footer>
      </div>
    );
  }

export default HomePage;