import Footer from "../components/Footer";

function AboutPage() {
    return (
      <div>
        <div className='home-text-wrapper'>
          <h1>Over de boerderij</h1>
          <p>Over deze pagina lees je binnenkort meer over de boerderij.<br /><br /> Kom snel een keertje terug om te zien wat we hebben toegevoegd.</p>
        </div>
        <Footer></Footer>
      </div>
    );
  }

  export default AboutPage;