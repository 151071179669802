import React, { useState } from "react";
import NavBar from "./NavBar";

function Header() {
    const [backTransparent, setBackTransparent] = useState(false);

    function setBack() {
      setBackTransparent(true);
    }
    
    setTimeout(setBack, 500);

  return <header className={backTransparent ? "App-header transparent" : "App-header"}>
    <NavBar />
      <h1>Tot Nut En Genoegen</h1>
      <h2>De Meern</h2>
    </header>;
}

export default Header;