import Footer from "../components/Footer";

function FaqPage() {
  return (
    <div> 
      <div className='home-text-wrapper'>
        <h1>Veelgestelde vragen</h1>
        <p>
        <img src="/koe.png" alt="Voorbeeld" className="content-image" />
          Op deeze pagina zullen wij veelgestelde vragen over de boerderij gaan beantwoorden.<br /><br /> Kom snel een keertje terug om te zien wat we voor je gemaakt hebben.
        </p>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default FaqPage;