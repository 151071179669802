import { NavLink } from "react-router-dom";
import './NavBar.scss'

function NavBar() {
    return (
      <div className="nav-bar">
        <NavLink to='/' exact={true}>Thuispagina</NavLink>
        <NavLink to='/over' exact={true}>Over de boerderij</NavLink>
        <NavLink to='/faq' exact={true}>Veelgestelde vragen</NavLink>
      </div>
    );
  }

  export default NavBar;